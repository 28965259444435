<!--Pagina del age gate-->
<template>
    <v-container
        fill-height
        fluid
        class="black d-flex align-stretch justify-center "
    >
    <v-row class="justify-center py-0 align-center llave">
        <v-col cols="12" md="11" class="d-flex justify-center">
            <v-responsive class="">
            <p class="text-center white--text pt-16 mt-16 mb-15 textoR">
                ¿TIENES 18 +?
            </p>
            <v-spacer></v-spacer>
            <v-row class="d-flex mt-n9 align-center justify-center ">
                <v-col cols="5" md="2"  class="px-md-10">
                    <!--Boton de SI-->
                    <v-btn
                        color="becks"
                        large
                        block
                        elevation="2"
                        max-width=""
                        rounded
                        data-qadp= "age_gate_yes"
                        @click="cambiarP('Justificacion')"
                    >
                        &nbsp;&nbsp;&nbsp;S&Iacute;&nbsp;&nbsp;&nbsp;
                    </v-btn>
                </v-col>
                <v-col cols="5" md="2" class="px-md-10">
                    <!--Boton de NO-->
                    <v-btn
                        color="becks"
                        large
                        elevation="2"
                        outlined
                        rounded
                        block
                        data-qadp= "age_gate_no"
                        href="https://www.tapintoyourbeer.com"
                    >
                        <v-spacer/>NO<v-spacer/>
                    </v-btn>
                </v-col>
            </v-row>
            </v-responsive>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center  justify-self-center mb-n16">
            <!--Check box de age gate-->
            <v-checkbox
                class="mx-auto d-block"
                dark
                v-model="checkbox"
                color="becks"
                label="RECORDAR MIS DATOS"
            ></v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center  justify-self-center text-center mt-md-n16 px-9">
            <!--Texto complementario a recordar tu elección-->
            <span class="white--text mt-n3 mx-auto" style="font-size: 8pt; width: 100%; display: block"> *No selecciones esta opción si compartes este computador con menores de edad.</span>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>
    import axios from "axios"
    export default {
    name: "Edad",
    data: () => ({
        checkbox: false
    }),
    methods: {
        cambiarP(lugar){//función para cambiar de pagina
                localStorage.ageDate = this.checkbox;
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    error => console.log('Hubo un error: ' + error)
                )
            }
    },
    components: {

    },
    created(){//Al abrir la pagina revisa si se activo la casilla de recordar elección y si es asi, se cambia de pagina
        if(localStorage.ageDate == 'true'){
            this.$router.push({name: 'Justificacion'})
        }
    }
};
</script>
<style scoped>

.llave {
        z-index: 1;
    }

.llave::after {
    /*Esta clase es para poner de fondo la llave con la transparencia */
    content: "";
    background: url(../assets/becksjustificacionllave.png);
    background-size:contain;
    background-repeat: no-repeat;
    opacity: 0.3;
    background-position: center;
    position: absolute;
    z-index: -1;   
    top: 10px;
    left:50px;
    bottom: 10px;
    right: 50px;
}
.dos{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
}
.textoR{
    font-size: 30pt;
}
@media (max-width: 960px){
    .textoR{
        font-size: 20pt;
    } 
}
</style>